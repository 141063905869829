var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq" }, [
    _c("div", { staticClass: "faq-bg d-none d-md-block" }),
    _vm._v(" "),
    _c("div", { staticClass: "nav-area-sm d-md-none" }, [
      _c(
        "div",
        { staticClass: "sub-routes-sm" },
        _vm._l(_vm.QuestionTypeMap, function (text, type) {
          return _c(
            "div",
            {
              key: type,
              staticClass: "sub-route-sm",
              class: {
                "sub-route-sm-active": "#" + type === _vm.currentSelected,
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "scrollactive-item",
                  attrs: { to: "/faq#" + type },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(text) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "faq-content container" }, [
      _c("div", { staticClass: "nav-area d-none d-md-block" }, [
        _c("div", { staticClass: "nav-area-content" }, [
          _c("div", { staticClass: "nav" }, [
            _c("div", { staticClass: "side-nav-bar" }, [
              _c("span", {
                staticClass: "side-nav-sign side-nav-background",
                staticStyle: {
                  "transform-origin": "0 0 0",
                  transform: "matrix(1, 0, 0, 1, 0, 0)",
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "side-nav-sign side-nav-indicator",
                staticStyle: {
                  "transform-origin": "0 0 0",
                  transform: "matrix(1, 0, 0, 1, 0, -3.6875)",
                  height: "70px",
                },
                style: _vm.indicatorClass,
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sub-routes" },
              _vm._l(_vm.QuestionTypeMap, function (text, type) {
                return _c(
                  "div",
                  { key: type, staticClass: "sub-route" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "sub-route-inner side-nav-link scrollactive-item",
                        class: { selected: "#" + type === _vm.currentSelected },
                        attrs: { to: "/faq#" + type },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(text) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-area" },
        _vm._l(_vm.QuestionTypeMap, function (tab, type) {
          return _c(
            "div",
            { key: type, staticClass: "issue", attrs: { id: "t-" + type } },
            [
              _c("a", { staticClass: "dark-anchor", attrs: { id: type } }),
              _vm._v(" "),
              _c("h3", { staticClass: "issue-title" }, [
                _vm._v(
                  "\n                    " + _vm._s(tab) + "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm._l(
                _vm.$t("webview.helpCenter.questiones")[type],
                function (ref, idx) {
                  var q = ref.q
                  var a = ref.a
                  return _c("div", { key: q, staticClass: "issue-text" }, [
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "text-left" }, [
                        _c("h6", [
                          _vm._v(_vm._s(idx + 1)),
                          _c("span", [_vm._v(".")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-right" }, [
                        _c("h6", [_vm._v(_vm._s(q))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(a))]),
                      ]),
                    ]),
                  ])
                }
              ),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }