<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-05-06 16:34:23.182 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div class="faq">
        <div class="faq-bg d-none d-md-block" />
        <div class="nav-area-sm d-md-none">
            <div class="sub-routes-sm">
                <div
                    v-for="(text, type) in QuestionTypeMap"
                    :key="type"
                    class="sub-route-sm"
                    :class="{'sub-route-sm-active': `#${type}` === currentSelected}"
                >
                    <router-link
                        :to="`/faq#${type}`"
                        class="scrollactive-item"
                    >
                        {{ text }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="faq-content container">
            <div class="nav-area d-none d-md-block">
                <div class="nav-area-content">
                    <div class="nav">
                        <div class="side-nav-bar">
                            <span
                                class="side-nav-sign side-nav-background"
                                style="transform-origin: 0 0 0; transform: matrix(1, 0, 0, 1, 0, 0);"
                            />
                            <span
                                class="side-nav-sign side-nav-indicator"
                                style="transform-origin: 0 0 0;
                                        transform: matrix(1, 0, 0, 1, 0, -3.6875);
                                        height: 70px;"
                                :style="indicatorClass"
                            />
                        </div>
                        <div class="sub-routes">
                            <div
                                v-for="(text, type) in QuestionTypeMap"
                                :key="type"
                                class="sub-route"
                            >
                                <router-link
                                    :to="`/faq#${type}`"
                                    class="sub-route-inner side-nav-link scrollactive-item"
                                    :class="{'selected': `#${type}` === currentSelected}"
                                >
                                    {{ text }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-area">
                <div
                    v-for="(tab,type) in QuestionTypeMap"
                    :id="`t-${type}`"
                    :key="type"
                    class="issue"
                >
                    <a
                        :id="type"
                        class="dark-anchor"
                    />
                    <h3 class="issue-title">
                        {{ tab }}
                    </h3>
                    <div
                        v-for="({q, a}, idx) in $t('webview.helpCenter.questiones')[type]"
                        :key="q"
                        class="issue-text"
                    >
                        <div class="text">
                            <div class="text-left">
                                <h6>{{ idx+1 }}<span>.</span></h6>
                            </div>
                            <div class="text-right">
                                <h6>{{ q }}</h6>
                                <p>{{ a }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/babel">
    export default {
        data() {
            return {
                QuestionTypeMap: this.$t('webview.helpCenter.questionTypeMap'),
                items: [],
                currentItem: this.$route.hash || '#loan',
                itemOffsetMap: {}
            };
        },

        computed: {
            currentSelected() {
                return this.currentItem;
            },

            indicatorClass() {
                if (!this.currentSelected) return null;
                const index = Object.keys(this.QuestionTypeMap).indexOf(this.currentSelected.substring(1));

                return `transform: translate(0, ${70 * index}px); transition: transform 0.3s cubic-bezier(0,.71,.16,1)`;
            }
        },

        mounted() {
            this.initScrollactiveItems();

            window.addEventListener('scroll', this.onScroll);
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll);
        },

        updated() {
            this.initScrollactiveItems();
        },

        methods: {
            onScroll() {
                this.currentItem = this.getItemInsideWindow();
            },

            getItemInsideWindow() {
                let last;
                for (let i = 0; i < this.items.length; i += 1) {
                    const thash = this.items[i].hash;
                    const distanceFromTop = window.pageYOffset;

                    if (this.itemOffsetMap[thash.substring(1)][0] > distanceFromTop) {
                        if (!last) last = thash;
                        break;
                    } else {
                        last = thash;
                    }
                }

                return last;
            },

            handleClick() {
                window.removeEventListener('scroll', this.onScroll);
                setTimeout(() => {
                    window.addEventListener('scroll', this.onScroll);
                }, 1000);
                this.currentItem = this.$route.hash;
            },

            initScrollactiveItems() {
                this.items = this.$el.querySelectorAll('.scrollactive-item');

                [].forEach.call(this.items, item => {
                    item.addEventListener('click', this.handleClick);
                    const name = item.hash.substring(1);
                    const target = document.getElementById(`t-${name}`);
                    this.itemOffsetMap[name] = [target.offsetTop, target.offsetHeight];
                });
            }
        }
    };
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~olacred/common/style/olacred";

    .faq {
        position: relative;
        height: 100%;
        background: #f4f9fc;

        .container {
            @media screen and (min-width: 1440px) {
                padding-right: 0;
            }
        }

        &-bg {
            height: 100%;
            width: 50%;
            position: absolute;
            background: $base-white;
            left: 0;
        }

        &-content {
            display: flex;
        }

        .nav-area {
            width: 30%;
            min-height: 70vh;
            background: $base-white;

            &-content {
                width: 100%;
                height: 100%;
            }
        }

        .nav-area-sm {
            position: sticky;
            top: 60px;
            overflow-x: auto;
            height: 50px;
            z-index: 6;
            background-color: #f0f0f0;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100vw;
                background: #f0f0f0;
            }

            a {
                margin: 0;
                color: rgba(0, 0, 0, 100%);
            }

            .sub-routes-sm {
                display: flex;
                align-items: center;
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                padding-left: 30px;
                -webkit-overflow-scrolling: touch;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .sub-route-sm {
                flex-shrink: 0;
                min-width: 30%;
                padding: 0 10px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: relative;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);

                @media screen and (max-width: $screen-sm) {
                    &-active {
                        a {
                            color: #333;
                        }
                    }

                    &-active::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        background: #ff734b;
                    }
                }
            }
        }

        .nav-image {
            position: absolute;
            top: 0;
        }

        .content-area {
            position: relative;
            padding-bottom: 80px;
            width: 100%;
            padding-left: 10%;
            background: #f4f9fc;

            @media screen and (max-width: $screen-md) {
                width: 100%;
                padding-bottom: 50px;
                padding-left: 0;
            }
        }

        .nav {
            position: sticky;
            top: 72px;
            padding: 72px 0;
            backface-visibility: hidden;
            perspective: 1000px;
            transform-style: preserve-3d;

            .side-nav-bar {
                position: absolute;
                top: 72px;
                left: 0;
                width: 4px;
                border-radius: 2px;
                height: 280px;
            }

            .side-nav-sign {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                border-radius: 4px;
            }

            .side-nav-background {
                height: 100%;
                background-color: #f0f0f0;
            }

            .side-nav-indicator {
                background: #ff734b;
            }
        }

        .sub-routes {
            padding: 0 20px;
            min-width: 500px;
            height: 268px;

            .sub-route {
                padding: 20px 0;

                &-inner {
                    display: flex;
                    align-items: center;
                    min-height: 30px;
                }

                a {
                    margin: 0;
                    padding-left: 20px;
                    color: #333;
                    font-size: 16px;
                    font-weight: 400;

                    &.selected {
                        color: #333;
                        cursor: default;
                        font-size: 18px;
                        font-family: Roboto-Bold, Roboto, sans-serif;
                        letter-spacing: 0;
                        font-weight: 900;

                        @media screen and (max-width: $screen-sm) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .side-nav-link {
                position: relative;
                display: block;
                margin: 0;
                color: #666;
                font-family: Roboto-Regular, Roboto, sans-serif;
                font-weight: 400;
                font-size: 18px;
                cursor: pointer;
                transition: color 0.2s ease-out, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
                transform-origin: 0 50%;
            }

            .border-left-deep {
                border-left: 4px solid #000;
            }
        }

        .issue-bg {
            background-color: rgba(244, 249, 252, 100%);
        }

        .issue {
            padding: 80px 0 0;

            @media screen and (max-width: $screen-md) {
                padding: 30px 0 0;
            }

            &-text {
                padding-top: 55px;

                @media screen and (max-width: $screen-md) {
                    padding-top: 20px;
                }
            }

            .dark-anchor {
                position: relative;
                top: -140px;
                width: 100px;
                height: 5px;
            }

            .text {
                display: flex;
                width: 100%;

                &-left {
                    min-width: 20px;

                    @media screen and (max-width: $screen-md) {
                        padding-top: 2px;
                    }

                    h6 {
                        font-size: 16px;
                        font-family: Roboto-Bold, Roboto;
                        font-weight: bold;
                        color: #666;
                    }

                    span {
                        color: #666;
                    }
                }

                &-right {
                    padding-left: 10px;

                    p {
                        padding-top: 2px;
                        font-family: Roboto-Light, Roboto, sans-serif;
                        font-weight: 300;
                        color: #333;
                        margin: 10px 0 0;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0;

                        @media screen and (max-width: $screen-sm) {
                            padding-top: 0;
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }

                    h6 {
                        font-size: 16px;
                        font-family: Roboto-Bold, Roboto, sans-serif;
                        font-weight: bold;
                        color: #333;

                        @media screen and (max-width: $screen-sm) {
                            margin: 0 0 10px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
</style>
